import * as React from "react"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Logo from "../svg/logo"
import SearchIcon from "../svg/search"
import YouTubeIcon from "../svg/youtube"
import FacebookIcon from "../svg/fb"
import InstagramIcon from "../svg/ig"
import TikTokIcon from "../svg/tiktok"
import Link from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { ThemeToggler } from "gatsby-plugin-dark-mode"

const Navbar = ({ siteTitle }) => {
  const [search, setSearch] = useState("")
  const [optionCategories, setCategories] = useState([])
  const [optionVenue, setVenue] = useState([])
  const [optionCuisine, setCuisine] = useState([])

  useEffect(() => {
    const queryParameters = new URLSearchParams(window?.location?.search)
    const searchString = queryParameters.get("s")
    const searchCategories = queryParameters.get("t")
    const searchVenue = queryParameters.get("l")
    const searchCuisine = queryParameters.get("ta")
    if (searchString == null) {
      setSearch("")
    } else {
      setSearch(searchString.trim())
    }
    if (searchCategories == null) {
      setCategories([])
    } else {
      setCategories(searchCategories.split(","))
    }
    if (searchVenue == null) {
      setVenue([])
    } else {
      setVenue(searchVenue.split(","))
    }
    if (searchCuisine == null) {
      setCuisine([])
    } else {
      setCuisine(searchCuisine.split(","))
    }
  }, [])

  const handleClick = event => {
    event.preventDefault()
    //console.log(optionCategories)
    window.location =
      "/search-results/?s=" +
      search +
      "&t=" +
      optionCategories?.join(",") +
      "&l=" +
      optionVenue?.join(",") +
      "&ta=" +
      optionCuisine?.join(",")
  }

  const handleChange = event => {
    var updatedCategories = [...optionCategories]
    var updatedVenue = [...optionVenue]
    var updatedCuisine = [...optionCuisine]
    if (
      event.target.type === "checkbox" &&
      event.target.name === "option_categories" &&
      event.target.checked
    ) {
      updatedCategories = [...optionCategories, event.target.value]
      setCategories(updatedCategories)
    } else if (
      event.target.type === "checkbox" &&
      event.target.name === "option_categories" &&
      !event.target.checked
    ) {
      updatedCategories.splice(optionCategories.indexOf(event.target.value), 1)
      setCategories(updatedCategories)
    } else if (
      event.target.type === "checkbox" &&
      event.target.name === "option_venue" &&
      event.target.checked
    ) {
      updatedVenue = [...optionVenue, event.target.value]
      setVenue(updatedVenue)
    } else if (
      event.target.type === "checkbox" &&
      event.target.name === "option_venue" &&
      !event.target.checked
    ) {
      updatedVenue.splice(optionVenue.indexOf(event.target.value), 1)
      setVenue(updatedVenue)
    } else if (
      event.target.type === "checkbox" &&
      event.target.name === "option_cuisine" &&
      event.target.checked
    ) {
      updatedCuisine = [...optionCuisine, event.target.value]
      setCuisine(updatedCuisine)
    } else if (
      event.target.type === "checkbox" &&
      event.target.name === "option_cuisine" &&
      !event.target.checked
    ) {
      updatedCuisine.splice(optionCuisine.indexOf(event.target.value), 1)
      setCuisine(updatedCuisine)
    } else {
      setSearch(event.target.value)
      //console.log(event.target.value)
    }
  }

  return (
    <div id="header">
      <div className="advertise text-uppercase text-center">
        <AniLink paintDrip hex="#ccb8a3" to="/contact">
          Advertise with us!
        </AniLink>
      </div>
      <div id="responsive-top1" className="advert"></div>

      {/* mobile nav */}
      <nav className="navbar d-block d-xxl-none">
        <div className="container-fluid py-4">
          <AniLink className="logo" paintDrip hex="#ccb8a3" to="/">
            <Logo />
          </AniLink>
          <button
            className="navbar-toggler custom-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#main-navbar"
            aria-controls="main-navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="main-navbar">
            <ul className="navbar-nav me-auto my-4">
              <li className="nav-item">
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/about"
                  activeClassName="active"
                  partiallyActive={true}
                  className="nav-link"
                >
                  About Us
                </AniLink>
              </li>
              <li className="nav-item">
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/eat"
                  activeClassName="active"
                  partiallyActive={true}
                  className="nav-link"
                >
                  Food Reviews
                </AniLink>
              </li>
              <li className="nav-item">
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/guides"
                  activeClassName="active"
                  partiallyActive={true}
                  className="nav-link"
                >
                  Food Guides
                </AniLink>
              </li>
              <li className="nav-item">
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/cook"
                  activeClassName="active"
                  partiallyActive={true}
                  className="nav-link"
                >
                  Recipes
                </AniLink>
              </li>
              <li className="nav-item">
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/travels"
                  activeClassName="active"
                  partiallyActive={true}
                  className="nav-link"
                >
                  Travel
                </AniLink>
              </li>
              <li className="nav-item">
                <a
                  href="https://towkayneo.sg"
                  target="_blank"
                  className="nav-link"
                  rel="noreferrer"
                >
                  Shop
                </a>
              </li>
              <li className="nav-item">
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/contact"
                  activeClassName="active"
                  partiallyActive={true}
                  className="nav-link"
                >
                  Contact Us
                </AniLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* end mobile nav */}

      {/* nav */}
      <div className="container d-none d-xxl-block">
        <div className="row">
          <div className="col-lg-4 offset-lg-4 p-5">
            <AniLink paintDrip hex="#ccb8a3" to="/">
              <Logo />
            </AniLink>
          </div>
          <div className="col-12 col-xl-4 pb-5 d-flex justify-content-end align-items-end">
            <ul className="listing_horizontal">
              <li>
                <a
                  href="https://www.youtube.com/channel/UC5qiiQs_Iajx7EmAznHI82A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/misstamchiak"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/misstamchiak"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@misstamchiak"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TikTokIcon />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <ul id="main_nav">
              <li>
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/about"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  About Us
                </AniLink>
              </li>
              <li>
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/eat"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  Food Reviews
                </AniLink>
              </li>
              <li>
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/guides"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  Food Guides
                </AniLink>
              </li>
              <li>
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/cook"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  Recipes
                </AniLink>
              </li>
              <li>
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/travels"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  Travel
                </AniLink>
              </li>
              <li>
                <a
                  class="nav-link"
                  href="https://towkayneo.sg"
                  target="_blank"
                  rel="noreferrer"
                >
                  Shop
                </a>
              </li>
              <li>
                <AniLink
                  paintDrip
                  hex="#ccb8a3"
                  to="/contact"
                  activeClassName="active"
                  partiallyActive={true}
                >
                  Contact Us
                </AniLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* end nav */}

      {/* theme toggler */}
      <div id="container_toggler" className="container">
        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <div className="dark-button">
              <input
                type="checkbox"
                id="toggle"
                onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
                checked={theme === "dark"}
              />
              <label for="toggle"></label>
            </div>
          )}
        </ThemeToggler>
      </div>
      {/* end theme toggler */}

      <div className="container">
        {/* search */}
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <form onSubmit={handleClick}>
              <div id="container_search" class="input-group mb-3">
                <input
                  class="form-control form-control-lg"
                  list="searchOptions"
                  id="textfield_search"
                  placeholder="Search"
                  value={search}
                  onChange={handleChange}
                />
                <button
                  class="btn"
                  type="button"
                  id="btn_search"
                  onClick={handleClick}
                >
                  <Link activeClassName="active" partiallyActive={true}>
                    <SearchIcon />
                  </Link>
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* end search */}

        {/* filter end filter */}
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div id="advanced_search" class="input-group mb-3">
              <button
                type="button"
                class="btn dropdown-toggle dropdown-toggle-split col-4"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                Categories
              </button>
              <ul class="dropdown-menu">
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Eat"
                      id="option_food_reviews"
                      name="option_categories"
                      checked={optionCategories.indexOf("Eat") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_food_reviews">
                      Food Reviews
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Guides"
                      id="option_food_guides"
                      name="option_categories"
                      checked={optionCategories.indexOf("Guides") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_food_guides">
                      Food Guides
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Cook"
                      id="option_recipes"
                      name="option_categories"
                      checked={optionCategories.indexOf("Cook") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_recipes">
                      Recipes
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Travel"
                      id="option_travel"
                      name="option_categories"
                      checked={optionCategories.indexOf("Travel") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_travel">
                      Travel
                    </label>
                  </div>
                </li>
              </ul>
              <button
                type="button"
                class="btn dropdown-toggle dropdown-toggle-split col-4"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                Venue
              </button>
              <ul class="dropdown-menu">
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Hawker"
                      id="option_hawker"
                      name="option_venue"
                      checked={optionVenue.indexOf("Hawker") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_hawker">
                      Hawker
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Restaurant"
                      id="option_restaurant"
                      name="option_venue"
                      checked={optionVenue.indexOf("Restaurant") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_restaurant">
                      Restaurant
                    </label>
                  </div>
                </li>
              </ul>
              <button
                type="button"
                class="btn dropdown-toggle dropdown-toggle-split col-4"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                Cuisine
              </button>
              <ul class="dropdown-menu">
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Food - Chinese"
                      name="option_cuisine"
                      id="option_chinese"
                      checked={optionCuisine.indexOf("Food - Chinese") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_chinese">
                      Chinese
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Food - European"
                      name="option_cuisine"
                      id="option_european"
                      checked={optionCuisine.indexOf("Food - European") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_european">
                      European
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Food - Indian"
                      name="option_cuisine"
                      id="option_indian"
                      checked={optionCuisine.indexOf("Food - Indian") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_indian">
                      Indian
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Food - International"
                      name="option_cuisine"
                      id="option_international"
                      checked={
                        optionCuisine.indexOf("Food - International") > -1
                      }
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_international">
                      International
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Food - Japanese"
                      name="option_cuisine"
                      id="option_japanese"
                      checked={optionCuisine.indexOf("Food - Japanese") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_japanese">
                      Japanese
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Korean Food"
                      name="option_cuisine"
                      id="option_korean"
                      checked={optionCuisine.indexOf("Korean Food") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_korean">
                      Korean
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Food - Malay"
                      name="option_cuisine"
                      id="option_malay"
                      checked={optionCuisine.indexOf("Food - Malay") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_malay">
                      Malay
                    </label>
                  </div>
                </li>
                <li>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="Food - Thai"
                      name="option_cuisine"
                      id="option_thai"
                      checked={optionCuisine.indexOf("Food - Thai") > -1}
                      onChange={handleChange}
                    />
                    <label class="form-check-label" for="option_thai">
                      Thai
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
