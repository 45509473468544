import * as React from "react"

function TikTokIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 30 30"
    >
      <path
        id="icons8-tiktok"
        d="M30.429,4H7.571A3.575,3.575,0,0,0,4,7.571V30.429A3.575,3.575,0,0,0,7.571,34H30.429A3.575,3.575,0,0,0,34,30.429V7.571A3.575,3.575,0,0,0,30.429,4ZM27.576,17.088c-.162.015-.326.025-.493.025a5.351,5.351,0,0,1-4.478-2.42v8.241a6.091,6.091,0,1,1-6.091-6.091c.127,0,.251.011.376.019v3a3.1,3.1,0,1,0-.376,6.179,3.185,3.185,0,0,0,3.234-3.07c0-.068.03-14,.03-14h2.869a5.349,5.349,0,0,0,4.929,4.775Z"
        transform="translate(-4 -4)"
        fill="currentColor"
      />
    </svg>
  )
}

export default TikTokIcon
