import * as React from "react"

function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 799.268 187.027"
    >
      <g
        id="Group_94"
        data-name="Group 94"
        transform="translate(-2653.979 -200.575)"
      >
        <g id="Group_93" data-name="Group 93">
          <g id="Group_87" data-name="Group 87">
            <path
              id="Path_85"
              data-name="Path 85"
              d="M2717.6,231.8l25.412,92.4-13.99,3.848-17.43-63.359,4.361,66.953-9.765,2.689-30.5-59.765,17.43,63.359-13.73,3.774-25.412-92.4,19.8-5.446,28.376,55.662-4.616-62.2Z"
              fill="currentColor"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M2740.992,227.575l14.254-2.726,18.007,94.133L2759,321.708Z"
              fill="currentColor"
            />
            <path
              id="Path_87"
              data-name="Path 87"
              d="M2784.618,294.268l-.5-3.387,13.6-3.515.519,3.525c1.011,6.908,5.15,10.592,10.84,9.761s8.691-4.875,7.881-10.427c-.873-5.96-3.948-9.941-16.138-18.532-16.192-11.327-21.326-18.049-22.856-28.476-2.064-14.091,4.716-24.5,18.4-26.5s23.157,6.013,25.243,20.241l.36,2.435-13.6,3.515-.4-2.705c-1.069-7.321-4.472-10.692-9.485-9.962s-7.4,4.266-6.526,10.232,4.488,9.855,16.138,18.531c15.843,11.793,21.326,18.044,22.856,28.477,1.985,13.545-6.071,24.687-19.754,26.693S2786.6,307.819,2784.618,294.268Z"
              fill="currentColor"
            />
            <path
              id="Path_88"
              data-name="Path 88"
              d="M2844.837,285.815l-.344-3.4,13.741-2.906.36,3.541c.7,6.945,4.674,10.809,10.4,10.232s8.9-4.484,8.336-10.068c-.609-5.992-3.5-10.1-15.3-19.23-15.673-12.036-20.505-18.975-21.564-29.461-1.44-14.17,5.8-24.258,19.553-25.656s22.871,7.04,24.322,21.342l.248,2.451-13.746,2.911-.275-2.726c-.746-7.357-3.991-10.883-9.03-10.369s-7.585,3.933-6.976,9.924,4.043,10.052,15.3,19.23c15.3,12.487,20.5,18.976,21.564,29.467,1.381,13.619-7.157,24.4-20.913,25.788S2846.219,299.44,2844.837,285.815Z"
              fill="currentColor"
            />
            <path
              id="Path_89"
              data-name="Path 89"
              d="M2929.527,204.482l46.208-2.292.678,13.666-15.858.789,4.07,82.027-14.492.72-4.071-82.027-15.858.783Z"
              fill="currentColor"
            />
            <path
              id="Path_90"
              data-name="Path 90"
              d="M3018.652,275.732l-15.6.339-2.684,21.283-14.508.313,13.794-96.155,19.436-.423,17.959,95.466-14.783.323Zm-2.218-13.921-6.759-39.979-5.013,40.233Z"
              fill="currentColor"
            />
            <path
              id="Path_91"
              data-name="Path 91"
              d="M3118.824,201.533l-1.392,95.816-14.509-.212.953-65.7-14.5,65.507-10.131-.148-12.6-65.9-.952,65.7-14.233-.206,1.386-95.816,20.532.3,11.709,61.368,12.931-61.008Z"
              fill="currentColor"
            />
            <path
              id="Path_92"
              data-name="Path 92"
              d="M3166.52,278.262l3.657-52.978c.927-13.381,10.714-22.036,24.232-21.1s22.025,10.851,21.1,24.232l-.773,11.2-14.2-.985.772-11.194c.387-5.6-2.794-9.248-7.706-9.586s-8.565,2.837-8.951,8.437l-3.663,52.979c-.386,5.594,2.795,9.247,7.707,9.585s8.57-2.837,8.956-8.437l.942-13.656,14.2.984-.947,13.651c-.921,13.381-10.713,22.036-24.226,21.1S3165.594,291.643,3166.52,278.262Z"
              fill="currentColor"
            />
            <path
              id="Path_93"
              data-name="Path 93"
              d="M3225.554,303.992,3235.8,208.7l14.429,1.551-4.319,40.159,17.155,1.847,4.314-40.159,14.434,1.551-10.247,95.292-14.435-1.551,4.468-41.519-17.155-1.847-4.462,41.524Z"
              fill="currentColor"
            />
            <path
              id="Path_94"
              data-name="Path 94"
              d="M3302.05,216.5l14.36,2.065L3302.8,313.392l-14.36-2.059Z"
              fill="currentColor"
            />
            <path
              id="Path_95"
              data-name="Path 95"
              d="M3353.282,300.588l-15.329-2.9-7.04,20.261-14.254-2.7,33.431-91.179,19.092,3.62-2.228,97.086-14.525-2.747Zm.719-14.074,1.678-40.5-13.243,38.306Z"
              fill="currentColor"
            />
            <path
              id="Path_96"
              data-name="Path 96"
              d="M3409.754,295.115l-8.131,8.66-7.833,27.8-13.963-3.938,25.994-92.227,13.963,3.938-10.544,37.417,28.991-32.219,15.016,4.235-30.658,33.6,6.114,59.039-15.017-4.234Z"
              fill="currentColor"
            />
          </g>
          <g id="Group_88" data-name="Group 88">
            <path
              id="Path_97"
              data-name="Path 97"
              d="M3232.239,339.466a1.473,1.473,0,0,1-.36-.047c-.6-.164-61.882-16.552-176.985-16.552s-176.382,16.388-176.986,16.552a1.356,1.356,0,0,1-.719-2.615c.608-.164,62.188-16.647,177.705-16.647S3231.99,336.64,3232.6,336.8a1.355,1.355,0,0,1-.36,2.662Z"
              fill="currentColor"
            />
          </g>
          <g id="Group_89" data-name="Group 89">
            <path
              id="Path_98"
              data-name="Path 98"
              d="M2888.526,387.268a1.356,1.356,0,0,1-.275-2.683c20.368-4.261,77.412-14.17,166.971-14.17,88.972,0,145.947,9.813,166.314,14.032a1.357,1.357,0,1,1-.55,2.657c-20.283-4.2-77.041-13.979-165.764-13.979-89.311,0-146.132,9.872-166.42,14.117A1.613,1.613,0,0,1,2888.526,387.268Z"
              fill="currentColor"
            />
          </g>
          <g id="Group_90" data-name="Group 90">
            <path
              id="Path_99"
              data-name="Path 99"
              d="M2889.373,365.138l-.148-.773,3.075-.937.154.8c.3,1.572,1.281,2.372,2.572,2.128a1.969,1.969,0,0,0,1.7-2.461c-.259-1.355-1-2.234-3.869-4.076-3.816-2.429-5.055-3.917-5.5-6.288-.614-3.2.837-5.642,3.944-6.235s5.351,1.148,5.965,4.377l.106.556-3.07.937-.117-.614c-.317-1.662-1.132-2.4-2.27-2.186s-1.646,1.048-1.387,2.4,1.122,2.207,3.869,4.075c3.737,2.541,5.05,3.917,5.5,6.283a5.357,5.357,0,1,1-10.523,2.012Z"
              fill="currentColor"
            />
            <path
              id="Path_100"
              data-name="Path 100"
              d="M2901.632,345.765l3.271-.55,3.647,21.6-3.271.551Z"
              fill="currentColor"
            />
            <path
              id="Path_101"
              data-name="Path 101"
              d="M2910.228,344.283l3.631-.545,6.362,13.952-2.175-14.577,3.223-.481,3.245,21.712-3.472.519-6.421-13.249,2.075,13.894-3.223.482Z"
              fill="currentColor"
            />
            <path
              id="Path_102"
              data-name="Path 102"
              d="M2934.613,347.813l-.233-1.831a2.011,2.011,0,1,0-3.98.5l1.487,11.877a2.011,2.011,0,1,0,3.981-.5l-.482-3.854-1.99.249-.392-3.112,5.288-.662.873,6.966a5.329,5.329,0,0,1-10.575,1.323l-1.482-11.878a5.326,5.326,0,0,1,10.57-1.323l.228,1.837Z"
              fill="currentColor"
            />
            <path
              id="Path_103"
              data-name="Path 103"
              d="M2950.715,356.3l-3.557.375-.2,4.912-3.308.35,1.3-22.21,4.43-.471,5.928,21.442-3.366.36Zm-.773-3.134-2.313-8.993-.37,9.279Z"
              fill="currentColor"
            />
            <path
              id="Path_104"
              data-name="Path 104"
              d="M2957.686,338.476l5.177-.455a4.891,4.891,0,0,1,5.632,4.753l.222,2.557a4.9,4.9,0,0,1-4.722,5.664l-1.868.164.773,8.85-3.3.291Zm5.881,9.412a1.856,1.856,0,0,0,1.847-2.2l-.233-2.684a1.86,1.86,0,0,0-2.2-1.847l-1.715.148.588,6.733Z"
              fill="currentColor"
            />
            <path
              id="Path_105"
              data-name="Path 105"
              d="M2973.893,354.075l-.852-11.941a5.328,5.328,0,0,1,10.629-.757l.852,11.941a5.328,5.328,0,0,1-10.629.757Zm7.316-.519-.853-11.946a2.011,2.011,0,1,0-4,.286l.852,11.946a2.012,2.012,0,1,0,4-.286Z"
              fill="currentColor"
            />
            <path
              id="Path_106"
              data-name="Path 106"
              d="M2997.22,357.574l-2.906-8.9-1.72.1.5,9.036-3.314.185-1.217-21.882,5.187-.291a4.894,4.894,0,0,1,5.484,4.933l.132,2.409a4.984,4.984,0,0,1-2.032,4.594l3.324,9.633Zm-4.8-11.931,1.721-.1a1.861,1.861,0,0,0,1.916-2.149l-.138-2.53a1.865,1.865,0,0,0-2.144-1.921l-1.72.1Z"
              fill="currentColor"
            />
            <path
              id="Path_107"
              data-name="Path 107"
              d="M3004.048,335.29l9.406-.4.132,3.128-6.092.254.254,6.1,4.849-.206.132,3.128-4.849.206.276,6.4,6.092-.254.132,3.123-9.4.4Z"
              fill="currentColor"
            />
            <path
              id="Path_108"
              data-name="Path 108"
              d="M3035.606,349.2l.037,1.789a4.9,4.9,0,0,1-5.092,5.346l-5.553.117-.46-21.945,5.266-.112a4.9,4.9,0,0,1,5.315,5.129l.021,1.16a4.473,4.473,0,0,1-2.133,4.245A4.433,4.433,0,0,1,3035.606,349.2Zm-7.681-11.629.127,5.96,1.472-.031a2.059,2.059,0,0,0,2.3-2.4l-.031-1.567a1.865,1.865,0,0,0-2.081-2Zm4.351,11.354a2.052,2.052,0,0,0-2.4-2.3l-1.757.037.143,6.579,2.069-.042a1.863,1.863,0,0,0,1.991-2.08Z"
              fill="currentColor"
            />
            <path
              id="Path_109"
              data-name="Path 109"
              d="M3040.11,334.2l9.432-.074.027,3.133-6.114.048.048,6.113,4.859-.037.027,3.134-4.86.037.053,6.426,6.109-.048.026,3.134-9.437.074Z"
              fill="currentColor"
            />
            <path
              id="Path_110"
              data-name="Path 110"
              d="M3053.883,350.968v-.783l3.2-.334v.815c-.005,1.6.81,2.573,2.122,2.573a1.965,1.965,0,0,0,2.139-2.091c0-1.381-.556-2.387-3.028-4.743-3.282-3.112-4.219-4.806-4.213-7.219.01-3.261,1.9-5.389,5.06-5.378s5.044,2.144,5.034,5.436v.561l-3.2.339v-.63c0-1.689-.651-2.573-1.811-2.573s-1.82.715-1.826,2.1.683,2.382,3.028,4.738c3.192,3.207,4.219,4.811,4.213,7.225a5.359,5.359,0,1,1-10.718-.032Z"
              fill="currentColor"
            />
            <path
              id="Path_111"
              data-name="Path 111"
              d="M3068.571,334.184l10.592.169-.048,3.134-3.636-.059-.3,18.807-3.324-.053.3-18.807-3.637-.058Z"
              fill="currentColor"
            />
            <path
              id="Path_112"
              data-name="Path 112"
              d="M3089.812,334.687l9.253.322-.106,3.123-5.939-.2-.222,6.4,4.689.164-.111,3.123-4.684-.159-.318,9.221-3.313-.111Z"
              fill="currentColor"
            />
            <path
              id="Path_113"
              data-name="Path 113"
              d="M3102.632,352.09l.572-11.941a5.32,5.32,0,0,1,10.628.5l-.571,11.941a5.32,5.32,0,0,1-10.629-.5Zm7.315.35.572-11.947a2.009,2.009,0,1,0-4-.19l-.571,11.946a2.008,2.008,0,1,0,4,.191Z"
              fill="currentColor"
            />
            <path
              id="Path_114"
              data-name="Path 114"
              d="M3118.03,352.868l.757-11.941a5.325,5.325,0,0,1,10.628.678l-.757,11.941a5.325,5.325,0,0,1-10.628-.678Zm7.315.466.757-11.941a2.011,2.011,0,1,0-4-.254l-.757,11.941a2.011,2.011,0,1,0,4,.254Z"
              fill="currentColor"
            />
            <path
              id="Path_115"
              data-name="Path 115"
              d="M3134.989,337l5.187.413a4.89,4.89,0,0,1,4.775,5.631l-.906,11.439a4.9,4.9,0,0,1-5.6,4.811l-5.187-.413Zm3.541,19.155a1.866,1.866,0,0,0,2.2-1.868l.915-11.571a1.861,1.861,0,0,0-1.868-2.191l-1.72-.138-1.239,15.631Z"
              fill="currentColor"
            />
            <path
              id="Path_116"
              data-name="Path 116"
              d="M3163.1,346.411l.19-1.837a2.012,2.012,0,1,0-3.991-.413l-1.233,11.91a2.012,2.012,0,1,0,3.991.413l.4-3.87-1.99-.206.317-3.118,5.3.551-.725,6.981a5.327,5.327,0,0,1-10.6-1.1l1.228-11.91a5.329,5.329,0,0,1,10.6,1.1l-.191,1.842Z"
              fill="currentColor"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M3170.061,357.542l2.123-16.98,3.3.413-2.122,16.98a1.854,1.854,0,1,0,3.668.455l2.122-16.975,3.234.4-2.122,16.98a4.861,4.861,0,0,1-5.706,4.467A4.913,4.913,0,0,1,3170.061,357.542Z"
              fill="currentColor"
            />
            <path
              id="Path_118"
              data-name="Path 118"
              d="M3187.676,342.568l3.287.466-3.07,21.712-3.287-.466Z"
              fill="currentColor"
            />
            <path
              id="Path_119"
              data-name="Path 119"
              d="M3196.278,343.807l5.134.82a4.9,4.9,0,0,1,4.309,5.992l-1.816,11.322a4.894,4.894,0,0,1-5.965,4.34l-5.135-.826Zm2.006,19.356a1.859,1.859,0,0,0,2.329-1.688l1.836-11.444a1.859,1.859,0,0,0-1.688-2.334l-1.7-.275-2.483,15.466Z"
              fill="currentColor"
            />
            <path
              id="Path_120"
              data-name="Path 120"
              d="M3211.67,346.358l9.258,1.715-.567,3.08-6-1.116-1.112,6,4.769.884-.571,3.075-4.769-.884-1.17,6.31,6,1.111-.566,3.075-9.263-1.715Z"
              fill="currentColor"
            />
          </g>
          <g id="Group_91" data-name="Group 91">
            <path
              id="Path_121"
              data-name="Path 121"
              d="M3245.668,360.877a13.71,13.71,0,0,0,2.191.174l-.249,1.768a14.8,14.8,0,0,0-2.181-.444l-.936-.133-.779,5.463c.895-.143,1.768-.323,3.325-.672l-.2,1.556c-1.249.344-1.249.344-3.361.778l-1.165,8.2c-.206,1.45-.879,1.763-3.213,1.434-.693-.1-1.122-.19-1.942-.36a4.694,4.694,0,0,0-.127-1.82,14.2,14.2,0,0,0,2.641.645c.725.106.937-.031,1.016-.592l1.022-7.183c-.143.037-.17.032-.456.1-.5.1-1.011.186-1.508.254a13.88,13.88,0,0,0-1.794.318l-.165-1.937a17.479,17.479,0,0,0,4.161-.424l.852-6-1.609-.228a14.528,14.528,0,0,0-2.218-.174l.26-1.8a13.283,13.283,0,0,0,2.143.471l1.636.233.37-2.6a16.985,16.985,0,0,0,.2-2.763l2.065.3a16.545,16.545,0,0,0-.572,2.651l-.376,2.652Zm2.175,9.141a15,15,0,0,0,.191-2.107,18.118,18.118,0,0,0,2.344.44l7.554,1.074a17.7,17.7,0,0,0,2.376.228,14.322,14.322,0,0,0-.407,2.1l-1.075,7.559a15.759,15.759,0,0,0-.249,2.7l-1.794-.259.212-1.472-8.766-1.244-.211,1.5-1.768-.248a17.468,17.468,0,0,0,.508-2.636Zm.6,7.543,8.76,1.249.477-3.351-8.76-1.244Zm2.033-14.334c-.154,1.074.317,1.249,4.684,1.868,5.145.731,5.2.71,5.928-2.657a5.668,5.668,0,0,0,1.53.953c-.577,2.133-1.053,3-1.932,3.419-.852.4-1.99.344-6.225-.259-5.144-.73-5.981-1.2-5.7-3.186l.656-4.6a15.073,15.073,0,0,0,.217-2.679l1.98.281a17.055,17.055,0,0,0-.524,2.551l-.143,1.016c4.562,0,8.384-.529,10.088-1.355l1.123,1.694a40.131,40.131,0,0,1-4.849.842,43.938,43.938,0,0,1-6.574.3Zm-1.355,9.538,8.765,1.244.429-3.028-8.76-1.244Z"
              fill="currentColor"
            />
            <path
              id="Path_122"
              data-name="Path 122"
              d="M3289.886,365.027a14.09,14.09,0,0,0,2.784.3l-.333,1.895a21.419,21.419,0,0,0-2.721-.646l-8.686-1.529-.672,2.715,7.59,1.339a18.877,18.877,0,0,0,2.425.318,17.588,17.588,0,0,0-.5,2.223l-2.26,12.8c-.3,1.683-.99,2.027-3.314,1.619a26.816,26.816,0,0,1-2.758-.624,4.755,4.755,0,0,0-.148-1.816,17.754,17.754,0,0,0,3.155.858c.99.174,1.281.09,1.376-.471l2.35-13.307-16.694-2.943-2.329,13.2a15.83,15.83,0,0,0-.355,2.773l-2-.355a18.392,18.392,0,0,0,.657-2.8l2.191-12.422a19.244,19.244,0,0,0,.3-2.319,20.952,20.952,0,0,0,2.413.54l6.22,1.1.7-2.716-7.829-1.381a19.5,19.5,0,0,0-2.752-.318l.333-1.9a17.141,17.141,0,0,0,2.7.672l7.929,1.4.079-.286c.132-.588.243-1.064.291-1.329a9.651,9.651,0,0,0,.132-1.08l1.98.487a10.948,10.948,0,0,0-.767,2.345l-.059.154Zm-9.946,8.437a13.946,13.946,0,0,0,2.732-3.293l1.545.768a25.308,25.308,0,0,1-2.567,2.827l1.794.317a14.083,14.083,0,0,0,2.372.281l-.3,1.709a15.046,15.046,0,0,0-2.355-.55l-3.9-.694-.508,2.859,4.3.762a14.349,14.349,0,0,0,2.371.28l-.3,1.684a15.125,15.125,0,0,0-2.35-.556l-4.277-.752-.408,2.324a23.785,23.785,0,0,0-.4,3.043l-1.9-.333a23.666,23.666,0,0,0,.662-2.969l.413-2.351-3.552-.629a15.972,15.972,0,0,0-2.456-.3l.3-1.683a15.253,15.253,0,0,0,2.435.572l3.526.619.508-2.858-3.261-.572a14.119,14.119,0,0,0-2.35-.281l.307-1.709a14.42,14.42,0,0,0,2.377.561Zm-3.747-4.489a28.425,28.425,0,0,1,1.212,3.271l-1.466.344a16.007,16.007,0,0,0-1.244-3.244Z"
              fill="currentColor"
            />
          </g>
          <g id="Group_92" data-name="Group 92">
            <path
              id="Path_123"
              data-name="Path 123"
              d="M2827.883,364.365a13.8,13.8,0,0,0,1.493-3.493l1.768.317a23.4,23.4,0,0,1-1.445,2.848l4.007-.72a18.88,18.88,0,0,0,2.641-.635l.318,1.762a18.6,18.6,0,0,0-2.7.318l-7.029,1.26.434,2.408,6.172-1.111a13.472,13.472,0,0,0,2.27-.546l.307,1.71a13.7,13.7,0,0,0-2.318.281l-6.172,1.106.466,2.593,8.146-1.466a17.934,17.934,0,0,0,2.641-.64l.318,1.762a20.253,20.253,0,0,0-2.694.35l-17.658,3.17a18.436,18.436,0,0,0-2.641.641l-.323-1.789a18.8,18.8,0,0,0,2.7-.323l7.855-1.408-.465-2.594-5.876,1.059a13.544,13.544,0,0,0-2.276.545l-.307-1.71a14.374,14.374,0,0,0,2.324-.28l5.875-1.054-.434-2.4-6.727,1.207a18.477,18.477,0,0,0-2.647.641l-.312-1.763a18.82,18.82,0,0,0,2.7-.323l3.764-.672a.717.717,0,0,0-.2-.212,13.358,13.358,0,0,0-2.2-1.979l1.37-.964a22.111,22.111,0,0,1,2.853,2.827Zm1.795,13.545a7.625,7.625,0,0,0,1.858,1.6,15.35,15.35,0,0,0,9.675,2.26,5.681,5.681,0,0,0-.5,1.969,20.149,20.149,0,0,1-7.76-1.588,12.525,12.525,0,0,1-4.7-3.568,10.6,10.6,0,0,1-3.2,5.346,18.958,18.958,0,0,1-6.178,3.674,5.623,5.623,0,0,0-1.138-1.562,16.189,16.189,0,0,0,6.463-3.641,8.082,8.082,0,0,0,2.356-3.928l-6.548,1.175a21.412,21.412,0,0,0-2.694.651l-.323-1.789a18.876,18.876,0,0,0,2.7-.317l7.082-1.271a7.171,7.171,0,0,0-.111-1.524,6.412,6.412,0,0,0-.164-.635l1.926-.318a5.008,5.008,0,0,0,.032.656,9.21,9.21,0,0,1,.026,1.514l8.173-1.471a17.807,17.807,0,0,0,2.641-.641l.323,1.789a21.586,21.586,0,0,0-2.779.339Z"
              fill="currentColor"
            />
            <path
              id="Path_124"
              data-name="Path 124"
              d="M2855.619,356.965c-.137.212-.249.392-.312.508a19.027,19.027,0,0,0,4.478,2.742,32.261,32.261,0,0,0,4.642,1.657,15.281,15.281,0,0,0,3.456.619,6.776,6.776,0,0,0-.608,1.837,29.2,29.2,0,0,1-4.5-1.233c.058.566.106,1.106.222,1.884l.667,4.61a15.062,15.062,0,0,0,.424,2.155c-.678.042-1.408.121-2.53.285l-10.666,1.546.889,6.135,2.335-.884c2.54-.974,2.54-.974,4.007-1.62l.285,1.6c-4.028,1.677-4.128,1.72-5.8,2.318a22.793,22.793,0,0,0-2.323.937l-.879-1.54c.524-.323.614-.662.519-1.689l-1.567-10.8c-.2-1.344-.339-2.144-.482-2.752-1.185.857-1.64,1.143-3.6,2.355a4.857,4.857,0,0,0-1.217-1.434,23.682,23.682,0,0,0,6.436-4.3,20.79,20.79,0,0,0,4.531-5.532Zm4.531,6.156c.72-.106,1.392-.2,1.816-.291-.085-.042-.112-.037-.2-.079a30.007,30.007,0,0,1-5.383-2.589c-.757-.492-.968-.651-1.953-1.387a31.285,31.285,0,0,1-5.859,6c.349-.053,1.127-.138,1.826-.239l4.314-.624a12.514,12.514,0,0,0-1.773-2.043l1.312-.958a18.747,18.747,0,0,1,2.2,2.747Zm-10.03,5.177,11.417-1.657-.323-2.228-11.417,1.656Zm.561,3.858,11.412-1.656-.35-2.44-11.417,1.656Zm5.3,1.445c2.853,1.011,3.2,1.149,4.658,1.731a24.251,24.251,0,0,0,4.647-4.721l1.647.8a48.284,48.284,0,0,1-4.733,4.515c.726.281.726.281,1.911.736a37.159,37.159,0,0,1,3.859,1.688l-.873,1.519a43.71,43.71,0,0,0-4.838-2.223c-3.441-1.445-3.441-1.445-3.96-1.646-1.3-.439-2.276-.788-2.942-1.021Z"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
