import * as React from "react"

function InstagramIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 43.941 43.929"
    >
      <path
        id="Path_84"
        data-name="Path 84"
        d="M3845.545,508.006a7.411,7.411,0,0,1-4.176,4.176c-2.891,1.147-9.753.883-12.949.883s-10.067.254-12.949-.883a7.413,7.413,0,0,1-4.176-4.176c-1.147-2.892-.882-9.753-.882-12.949s-.255-10.067.882-12.95a7.414,7.414,0,0,1,4.176-4.175c2.892-1.148,9.753-.883,12.949-.883s10.067-.255,12.949.883a7.413,7.413,0,0,1,4.176,4.175c1.147,2.892.882,9.754.882,12.95S3846.692,505.124,3845.545,508.006ZM3850.231,486c-.167-3.519-.971-6.636-3.549-9.2s-5.685-3.371-9.2-3.548c-3.627-.205-14.5-.205-18.125,0-3.51.167-6.627.971-9.205,3.538s-3.372,5.686-3.549,9.205c-.206,3.628-.206,14.5,0,18.125.167,3.519.971,6.637,3.549,9.2s5.686,3.372,9.205,3.548c3.627.206,14.5.206,18.125,0,3.519-.166,6.636-.97,9.2-3.548s3.372-5.686,3.549-9.2c.206-3.627.206-14.487,0-18.115Zm-7.46-2.666a2.627,2.627,0,1,1-2.627-2.627A2.621,2.621,0,0,1,3842.771,483.333ZM3828.42,502.38a7.323,7.323,0,1,1,7.323-7.323,7.336,7.336,0,0,1-7.323,7.323Zm0-18.586a11.263,11.263,0,1,0,11.263,11.263A11.244,11.244,0,0,0,3828.42,483.794Z"
        transform="translate(-3806.445 -473.092)"
        fill="currentColor"
      />
    </svg>
  )
}

export default InstagramIcon
