exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advertise-js": () => import("./../../../src/pages/advertise.js" /* webpackChunkName: "component---src-pages-advertise-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-food-guides-design-js": () => import("./../../../src/pages/food-guides-design.js" /* webpackChunkName: "component---src-pages-food-guides-design-js" */),
  "component---src-pages-food-reviews-design-details-js": () => import("./../../../src/pages/food-reviews-design/details.js" /* webpackChunkName: "component---src-pages-food-reviews-design-details-js" */),
  "component---src-pages-food-reviews-design-index-js": () => import("./../../../src/pages/food-reviews-design/index.js" /* webpackChunkName: "component---src-pages-food-reviews-design-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-design-js": () => import("./../../../src/pages/recipes-design.js" /* webpackChunkName: "component---src-pages-recipes-design-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-food-reviews-template-js": () => import("./../../../src/templates/food-reviews-template.js" /* webpackChunkName: "component---src-templates-food-reviews-template-js" */),
  "component---src-templates-listing-template-js": () => import("./../../../src/templates/listing-template.js" /* webpackChunkName: "component---src-templates-listing-template-js" */)
}

