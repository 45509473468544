import * as React from "react"

function FacebookIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 46.681 46.398"
    >
      <path
        id="Path_83"
        data-name="Path 83"
        d="M3768.423,493.664a23.341,23.341,0,1,0-26.987,23.059V500.411h-5.93v-6.747h5.93v-5.142c0-5.849,3.482-9.08,8.815-9.08a35.888,35.888,0,0,1,5.226.456v5.741h-2.944c-2.9,0-3.8,1.8-3.8,3.644v4.381h6.474l-1.036,6.747h-5.438v16.312A23.35,23.35,0,0,0,3768.423,493.664Z"
        transform="translate(-3721.742 -470.325)"
        fill="currentColor"
      />
    </svg>
  )
}

export default FacebookIcon
