/* import */
import * as React from "react"
import YouTubeIcon from "../svg/youtube"
import FacebookIcon from "../svg/fb"
import InstagramIcon from "../svg/ig"
import TikTokIcon from "../svg/tiktok"
import { Link } from "gatsby"

/* define */
const Footer = () => {
  return (
    <div id="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-5 text-uppercase mb-5 mb-md-0">
            <p className="pb-md-5">
              <a
                href="https://www.stitchinteractive.com"
                target="_blank"
                rel="noreferrer"
              >
                Site by Stitch Interactive
              </a>
            </p>
            <p className="mb-5 mb-md-0">
              <ul className="listing_horizontal">
                <li>
                  <Link to="/terms-of-use">Terms of use</Link>
                </li>
                <li>&#x2022;</li>
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
              </ul>
            </p>
          </div>
          <div className="col-12 col-md-4 col-lg-3 col-xl-4 mb-5 mb-md-0">
            <h4 className="text-uppercase">Socials</h4>
            <ul className="listing_horizontal">
              <li>
                <a
                  href="https://www.youtube.com/channel/UC5qiiQs_Iajx7EmAznHI82A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <YouTubeIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/misstamchiak"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/misstamchiak"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@misstamchiak"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TikTokIcon />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <h4 className="text-uppercase">Latest News</h4>
            {/*
            <div class="input-group px-5 pb-2 px-md-0 input_transparent">
              <input
                type="text"
                class="form-control"
                placeholder="Enter your email"
                aria-label="Enter your email"
                aria-describedby="btn_subscribe"
              />
              <button class="btn" type="button" id="btn_subscribe">
                Subscribe
              </button>
            </div>
            */}
            <div className="font_sm">
              Subscribe to our&nbsp;
              <a
                href="https://t.me/misstamchiak"
                target="_blank"
                rel="noreferrer"
              >
                Telegram
              </a>
              &nbsp;channel to receive latest news
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/* export */
export default Footer
