import * as React from "react"

function SearchIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 28.927 28.927"
    >
      <path
        id="Icon_ionic-md-search"
        data-name="Icon ionic-md-search"
        d="M25.1,22.683H23.785l-.495-.413a10.941,10.941,0,0,0,2.555-7.025A10.673,10.673,0,1,0,15.214,25.989a11.045,11.045,0,0,0,7.005-2.562l.494.413v1.322l8.241,8.265,2.472-2.479Zm-9.89,0a7.438,7.438,0,1,1,7.417-7.438A7.4,7.4,0,0,1,15.214,22.683Z"
        transform="translate(-4.5 -4.5)"
        fill="currentColor"
      />
    </svg>
  )
}

export default SearchIcon
