import * as React from "react"

function YouTubeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 46.697 46.697"
    >
      <g
        id="Group_95"
        data-name="Group 95"
        transform="translate(-3639.929 -470.325)"
      >
        <path
          id="Path_125"
          data-name="Path 125"
          d="M3663.278,470.325a23.348,23.348,0,1,0,23.348,23.348A23.348,23.348,0,0,0,3663.278,470.325Zm13.933,26.75a6.336,6.336,0,0,1-6.336,6.336h-16.2a6.337,6.337,0,0,1-6.337-6.336v-7.541a6.337,6.337,0,0,1,6.337-6.336h16.2a6.336,6.336,0,0,1,6.336,6.336Z"
          fill="currentColor"
        />
        <path
          id="Path_126"
          data-name="Path 126"
          d="M3667.38,493.423l-6.956-3.784c-.285-.162-1.26.043-1.26.371v7.379c0,.324.967.53,1.252.376l7.266-3.6C3667.977,494.011,3667.671,493.589,3667.38,493.423Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default YouTubeIcon
