import * as React from "react"

function BackToTopIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="119"
      viewBox="0 0 86 119"
    >
      <defs>
        <filter
          id="Path_165"
          x="0"
          y="0"
          width="86"
          height="119"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.161" result="color" />
          <feComposite operator="out" in="SourceGraphic" in2="blur" />
          <feComposite operator="in" in="color" />
          <feComposite operator="in" in2="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_2649"
        data-name="Group 2649"
        transform="translate(-1750 -5959.527)"
      >
        <g data-type="innerShadowGroup">
          <g
            transform="matrix(1, 0, 0, 1, 1750, 5959.53)"
            filter="url(#Path_165)"
          >
            <g id="Path_165-2" data-name="Path 165" fill="#fff">
              <path
                d="M43,0A43,43,0,0,1,86,43V76A43,43,0,0,1,0,76V43A43,43,0,0,1,43,0Z"
                stroke="none"
              />
              <path
                d="M 43 4 C 37.73328018188477 4 32.62620162963867 5.030601501464844 27.82065963745117 7.063179016113281 C 23.17705917358398 9.027259826660156 19.00582122802734 11.83985900878906 15.42283630371094 15.42283630371094 C 11.83985900878906 19.00582122802734 9.027259826660156 23.17706298828125 7.063179016113281 27.82066345214844 C 5.030601501464844 32.62619781494141 4 37.7332763671875 4 43 L 4 76 C 4 81.2667236328125 5.030601501464844 86.37379455566406 7.063179016113281 91.17933654785156 C 9.027259826660156 95.82293701171875 11.83985900878906 99.99417877197266 15.42283630371094 103.5771636962891 C 19.00582122802734 107.1601409912109 23.17705917358398 109.9727401733398 27.82065963745117 111.9368209838867 C 32.62620162963867 113.9693984985352 37.73328018188477 115 43 115 C 48.26671981811523 115 53.37379837036133 113.9693984985352 58.17934036254883 111.9368209838867 C 62.82294082641602 109.9727401733398 66.99417877197266 107.1601409912109 70.57716369628906 103.5771636962891 C 74.16014099121094 99.99417877197266 76.97274017333984 95.82293701171875 78.93682098388672 91.17933654785156 C 80.96939849853516 86.37379455566406 82 81.2667236328125 82 76 L 82 43 C 82 37.7332763671875 80.96939849853516 32.62619781494141 78.93682098388672 27.82066345214844 C 76.97274017333984 23.17706298828125 74.16014099121094 19.00582122802734 70.57716369628906 15.42283630371094 C 66.99417877197266 11.83985900878906 62.82294082641602 9.027259826660156 58.17934036254883 7.063179016113281 C 53.37379837036133 5.030601501464844 48.26671981811523 4 43 4 M 43 0 C 66.74823760986328 0 86 19.25176239013672 86 43 L 86 76 C 86 99.74823760986328 66.74823760986328 119 43 119 C 19.25176239013672 119 0 99.74823760986328 0 76 L 0 43 C 0 19.25176239013672 19.25176239013672 0 43 0 Z"
                stroke="none"
              />
            </g>
          </g>
          <g
            id="Path_165-3"
            data-name="Path 165"
            transform="translate(1750 5959.527)"
            fill="#fff6eb"
          >
            <path
              d="M43,0A43,43,0,0,1,86,43V76A43,43,0,0,1,0,76V43A43,43,0,0,1,43,0Z"
              stroke="none"
            />
            <path
              d="M 43 4 C 37.73328018188477 4 32.62620162963867 5.030601501464844 27.82065963745117 7.063179016113281 C 23.17705917358398 9.027259826660156 19.00582122802734 11.83985900878906 15.42283630371094 15.42283630371094 C 11.83985900878906 19.00582122802734 9.027259826660156 23.17706298828125 7.063179016113281 27.82066345214844 C 5.030601501464844 32.62619781494141 4 37.7332763671875 4 43 L 4 76 C 4 81.2667236328125 5.030601501464844 86.37379455566406 7.063179016113281 91.17933654785156 C 9.027259826660156 95.82293701171875 11.83985900878906 99.99417877197266 15.42283630371094 103.5771636962891 C 19.00582122802734 107.1601409912109 23.17705917358398 109.9727401733398 27.82065963745117 111.9368209838867 C 32.62620162963867 113.9693984985352 37.73328018188477 115 43 115 C 48.26671981811523 115 53.37379837036133 113.9693984985352 58.17934036254883 111.9368209838867 C 62.82294082641602 109.9727401733398 66.99417877197266 107.1601409912109 70.57716369628906 103.5771636962891 C 74.16014099121094 99.99417877197266 76.97274017333984 95.82293701171875 78.93682098388672 91.17933654785156 C 80.96939849853516 86.37379455566406 82 81.2667236328125 82 76 L 82 43 C 82 37.7332763671875 80.96939849853516 32.62619781494141 78.93682098388672 27.82066345214844 C 76.97274017333984 23.17706298828125 74.16014099121094 19.00582122802734 70.57716369628906 15.42283630371094 C 66.99417877197266 11.83985900878906 62.82294082641602 9.027259826660156 58.17934036254883 7.063179016113281 C 53.37379837036133 5.030601501464844 48.26671981811523 4 43 4 M 43 0 C 66.74823760986328 0 86 19.25176239013672 86 43 L 86 76 C 86 99.74823760986328 66.74823760986328 119 43 119 C 19.25176239013672 119 0 99.74823760986328 0 76 L 0 43 C 0 19.25176239013672 19.25176239013672 0 43 0 Z"
              stroke="none"
              fill="#7e0c1a"
            />
          </g>
        </g>
        <path
          id="Icon_awesome-long-arrow-alt-up"
          data-name="Icon awesome-long-arrow-alt-up"
          d="M5.267,15.56V45.538c0,.658.307,1.191.686,1.191h3.2c.379,0,.686-.533.686-1.191V15.56h2.634c1.223,0,1.835-2.567.97-4.068L8.525,2.948a1.024,1.024,0,0,0-1.941,0L1.663,11.492c-.865,1.5-.252,4.068.97,4.068Z"
          transform="translate(1785.445 5974.299)"
        />
        <text
          id="TOP"
          transform="translate(1772 6032.527)"
          font-size="20"
          font-family="OpenSans-Bold, Open Sans"
          font-weight="700"
          letter-spacing="0.08em"
        >
          <tspan x="0" y="21">
            TOP
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default BackToTopIcon
